import { combineReducers } from "redux";

import settingsReducer from "./settings.reducer.js";
import themesReducer from "./themes.reducers.js";
import formReducer from "./form.reducer.js";
import loginReducer from "./login.reducer.js";
import profileReducer from "./profile.reducer.js";
import snackbarReducer from "./snackbar.reducer.js";
import userDetailsReducer from "./userDetails.reducer.js";
import settingsDetailsReducer from "./settingsScreen.reducer";
import userReducer from "./user.reducer.js";
import pumpDataReducer from "./pumpData.reducer.js";
import flowMeterReducer from "./flowMeter.reducer.js";
import motorDataReducer from "./motorData.reducer.js";
import importReducer from "./import.reducer.js";
import graphReducer from "./graph.reducer.js";
import exportReducer from "./export.reducer.js";

export default combineReducers({
  settings: settingsReducer,
  theme: themesReducer,
  form: formReducer,
  login: loginReducer,
  profile: profileReducer,
  snackbar: snackbarReducer,
  userDetails: userDetailsReducer,
  settingsDetails: settingsDetailsReducer,
  user: userReducer,
  pumpData: pumpDataReducer,
  flowMeter: flowMeterReducer,
  motorData: motorDataReducer,
  import: importReducer,
  graph: graphReducer,
  export: exportReducer,
});
