export const SET_PRINT_DETAILS = "SET_PRINT_DETAILS";
export const SET_SETTINGS_DETAILS_INITIAL_STATE = "SET_SETTINGS_DETAILS_INITIAL_STATE";
export const GET_SETTINGS_DETAILS_PENDING = "GET_SETTINGS_DETAILS_PENDING";
export const GET_SETTINGS_DETAILS_SUCCESS = "GET_SETTINGS_DETAILS_SUCCESS";
export const CLOSE_EDIT_PRINT_DETAILS_MODAL = "CLOSE_EDIT_PRINT_DETAILS_MODAL";
export const OPEN_EDIT_PRINT_DETAILS_MODAL = "OPEN_EDIT_PRINT_DETAILS_MODAL";
export const UPDATE_PRINT_DETAILS_PENDING = "UPDATE_PRINT_DETAILS_PENDING";
export const UPDATE_PRINT_DETAILS_SUCCESS = "UPDATE_PRINT_DETAILS_SUCCESS";
export const UPDATE_PRINT_DETAILS_ERROR = "UPDATE_PRINT_DETAILS_ERROR";
export const CLEAR_UPDATE_PRINT_DETAILS_ERROR = "CLEAR_UPDATE_PRINT_DETAILS_ERROR";
