export const CREATE_MOTORDATA_SUCCESS = "CREATE_MOTORDATA_SUCCESS";
export const CREATE_MOTORDATA_ERROR = "CREATE_MOTORDATA_ERROR";
export const CREATE_MOTORDATA_PENDING = "CREATE_MOTORDATA_PENDING";
export const CLEAR_MOTORDATA_ERROR = "CLEAR_MOTORDATA_ERROR";
export const SET_INITIAL_STATE = "SET_INITIAL_STATE";
export const OPEN_CREATE_MOTORDATA_MODAL = "OPEN_CREATE_MOTORDATA_MODAL";
export const CLOSE_CREATE_MOTORDATA_MODAL = "CLOSE_CREATE_MOTORDATA_MODAL";
export const SET_MOTORDATAS_PENDING = "SET_MOTORDATAS_PENDING";
export const SET_MOTORDATAS_LIST = "SET_MOTORDATAS_LIST";
export const SET_MOTORDATA_DETAILS_INITIAL_STATE = "SET_MOTORDATA_DETAILS_INITIAL_STATE";
export const SET_MOTORDATA_DETAILS = "SET_MOTORDATA_DETAILS";
export const GET_MOTORDATA_DETAILS_PENDING = "GET_MOTORDATA_DETAILS_PENDING";
export const GET_MOTORDATA_DETAILS_SUCCESS = "GET_MOTORDATA_DETAILS_SUCCESS";
export const CLOSE_EDIT_MOTORDATA_MODAL = "CLOSE_EDIT_MOTORDATA_MODAL";
export const OPEN_EDIT_MOTORDATA_MODAL = "OPEN_EDIT_MOTORDATA_MODAL";
export const UPDATE_MOTORDATA_PENDING = "UPDATE_MOTORDATA_PENDING";
export const UPDATE_MOTORDATA_SUCCESS = "UPDATE_MOTORDATA_SUCCESS";
export const UPDATE_MOTORDATA_ERROR = "UPDATE_MOTORDATA_ERROR";
export const CLEAR_UPDATE_MOTORDATA_ERROR = "CLEAR_UPDATE_MOTORDATA_ERROR";
export const SET_MOTORDATA_LIST_ASYNC = "SET_MOTORDATA_LIST_ASYNC";
