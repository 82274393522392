import type { SettingsState } from "../../types/settingsScreen";
import {
  CLEAR_UPDATE_PRINT_DETAILS_ERROR,
  CLOSE_EDIT_PRINT_DETAILS_MODAL,
  GET_SETTINGS_DETAILS_PENDING,
  GET_SETTINGS_DETAILS_SUCCESS,
  OPEN_EDIT_PRINT_DETAILS_MODAL,
  SET_PRINT_DETAILS,
  SET_SETTINGS_DETAILS_INITIAL_STATE,
  UPDATE_PRINT_DETAILS_ERROR,
  UPDATE_PRINT_DETAILS_PENDING,
  UPDATE_PRINT_DETAILS_SUCCESS,
} from "../constants/settingsScreen.constants";

const initialState: SettingsState = {
  isGetSettingsDetailsPending: false,
  isEditPrintDetailsModalOpen: false,
  errorMessage: "",
  updatePrintDetailsErrorMessage: "",
  isUpdatePrintDetailsPending: false,
  printDetails: null,
};

export default (
  localState: SettingsState = initialState,
  action: Action
): SettingsState => {
  switch (action.type) {
    case SET_SETTINGS_DETAILS_INITIAL_STATE: {
      return {
        ...initialState,
      };
    }
    case SET_PRINT_DETAILS: {
      return {
        ...localState,
        printDetails: action.payload,
      };
    }
    case GET_SETTINGS_DETAILS_PENDING: {
      return {
        ...localState,
        isGetSettingsDetailsPending: true,
      };
    }
    case GET_SETTINGS_DETAILS_SUCCESS: {
      return {
        ...localState,
        isGetSettingsDetailsPending: false,
      };
    }
    case OPEN_EDIT_PRINT_DETAILS_MODAL: {
      return {
        ...localState,
        isEditPrintDetailsModalOpen: true,
      };
    }
    case CLOSE_EDIT_PRINT_DETAILS_MODAL: {
      return {
        ...localState,
        isEditPrintDetailsModalOpen: false,
        updatePrintDetailsErrorMessage: null,
      };
    }
    case UPDATE_PRINT_DETAILS_PENDING: {
      return {
        ...localState,
        isUpdatePrintDetailsPending: true,
      };
    }
    case UPDATE_PRINT_DETAILS_SUCCESS: {
      return {
        ...localState,
        isUpdatePrintDetailsPending: false,
      };
    }
    case UPDATE_PRINT_DETAILS_ERROR: {
      const { errorMessage } = action.payload;
      return {
        ...localState,
        updatePrintDetailsErrorMessage: errorMessage,
        isUpdatePrintDetailsPending: false,
      };
    }
    case CLEAR_UPDATE_PRINT_DETAILS_ERROR: {
      return {
        ...localState,
        updatePrintDetailsErrorMessage: null,
        isUpdatePrintDetailsPending: false,
      };
    }
    default: {
      return localState;
    }
  }
};
