import { MotorDataState } from "../../types/motorData";
import {
  CREATE_MOTORDATA_ERROR,
  CREATE_MOTORDATA_PENDING,
  CREATE_MOTORDATA_SUCCESS,
  CLEAR_MOTORDATA_ERROR,
  SET_INITIAL_STATE,
  OPEN_CREATE_MOTORDATA_MODAL,
  CLOSE_CREATE_MOTORDATA_MODAL,
  SET_MOTORDATAS_PENDING,
  SET_MOTORDATAS_LIST,
  SET_MOTORDATA_DETAILS_INITIAL_STATE,
  SET_MOTORDATA_DETAILS,
  GET_MOTORDATA_DETAILS_PENDING,
  GET_MOTORDATA_DETAILS_SUCCESS,
  OPEN_EDIT_MOTORDATA_MODAL,
  CLOSE_EDIT_MOTORDATA_MODAL,
  UPDATE_MOTORDATA_PENDING,
  UPDATE_MOTORDATA_SUCCESS,
  UPDATE_MOTORDATA_ERROR,
  CLEAR_UPDATE_MOTORDATA_ERROR,
  SET_MOTORDATA_LIST_ASYNC,
} from "../constants/motorData.constants";

const initialState: MotorDataState = {
  errorMessage: null,
  isPending: false,
  isCreateMotorDataModalOpen: false,
  isGetMotorDataPending: false,
  motorDatas: [],
  searchValueAsync: "",
  isGetMotorDataDetailsPending: false,
  isEditMotorDataModalOpen: false,
  updateMotorDataErrorMessage: "",
  isUpdateMotorDataPending: false,
  motorData: null,
};

export default (
  localState: MotorDataState = initialState,
  action: Action
): MotorDataState => {
  switch (action.type) {
    case SET_INITIAL_STATE: {
      return {
        ...initialState,
        isCreateMotorDataModalOpen: true,
      };
    }
    case CREATE_MOTORDATA_SUCCESS: {
      return {
        ...localState,
        isPending: false,
      };
    }
    case CREATE_MOTORDATA_ERROR: {
      const { errorMessage } = action.payload;
      return {
        ...localState,
        errorMessage,
        isPending: false,
      };
    }
    case CREATE_MOTORDATA_PENDING: {
      return {
        ...localState,
        isPending: true,
      };
    }
    case CLEAR_MOTORDATA_ERROR: {
      return {
        ...localState,
        errorMessage: null,
      };
    }
    case OPEN_CREATE_MOTORDATA_MODAL: {
      return {
        ...localState,
        isCreateMotorDataModalOpen: true,
      };
    }
    case CLOSE_CREATE_MOTORDATA_MODAL: {
      return {
        ...localState,
        errorMessage: null,
        isCreateMotorDataModalOpen: false,
      };
    }
    case SET_MOTORDATAS_PENDING: {
      return {
        ...localState,
        isGetMotorDataPending: true,
      };
    }
    case SET_MOTORDATAS_LIST: {
      const { motorDatasOdata } = action.payload;
      return {
        ...localState,
        isGetMotorDataPending: false,
        motorDatas: motorDatasOdata && motorDatasOdata.value,
      };
    }
    case SET_MOTORDATA_DETAILS_INITIAL_STATE: {
      return {
        ...initialState,
      };
    }
    case SET_MOTORDATA_DETAILS: {
      return {
        ...localState,
        motorData: action.payload,
      };
    }
    case GET_MOTORDATA_DETAILS_PENDING: {
      return {
        ...localState,
        isGetMotorDataDetailsPending: true,
      };
    }
    case GET_MOTORDATA_DETAILS_SUCCESS: {
      return {
        ...localState,
        isGetMotorDataDetailsPending: false,
      };
    }
    case OPEN_EDIT_MOTORDATA_MODAL: {
      return {
        ...localState,
        isEditMotorDataModalOpen: true,
      };
    }
    case CLOSE_EDIT_MOTORDATA_MODAL: {
      return {
        ...localState,
        isEditMotorDataModalOpen: false,
        updateMotorDataErrorMessage: null,
      };
    }
    case UPDATE_MOTORDATA_PENDING: {
      return {
        ...localState,
        isUpdateMotorDataPending: true,
      };
    }
    case UPDATE_MOTORDATA_SUCCESS: {
      return {
        ...localState,
        isUpdateMotorDataPending: false,
      };
    }
    case UPDATE_MOTORDATA_ERROR: {
      const { errorMessage } = action.payload;
      return {
        ...localState,
        updateMotorDataErrorMessage: errorMessage,
        isUpdateMotorDataPending: false,
      };
    }
    case CLEAR_UPDATE_MOTORDATA_ERROR: {
      return {
        ...localState,
        updateMotorDataErrorMessage: null,
        isUpdateMotorDataPending: false,
      };
    }
    case SET_MOTORDATA_LIST_ASYNC: {
      const { motorDataListAsync } = action.payload;
      return {
        ...localState,
        motorDataListAsync
      };
    }
    default: {
      return localState;
    }
  }
};
