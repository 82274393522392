import { createStore, applyMiddleware } from "redux";
import middlewares from "./middlewares/middlewares";

import { updateTheme } from "./middlewares/themes.middleware.js";

import { persistedState, saveState } from "./persisted.store.js";
import thunk from "redux-thunk";
import { USER_LOGOUT } from "./constants/login.constants";
import rootReducer from "./reducers/reducers";

const appReducer = (state, action) => {
  if (action.type === USER_LOGOUT) {
    sessionStorage.clear();
    state = undefined;
  }

  return rootReducer(state, action);
};

export default function configureStore() {
  const store = createStore(
    appReducer,
    persistedState, // second argument overrides the initial state
    applyMiddleware(...middlewares, thunk)
  );

  // add a listener that will be invoked on any state change
  store.subscribe(() => {
    saveState(store.getState());
  });

  // Update the initial theme
  updateTheme(store.getState());

  return store;
}
